import React, { ReactNode, useEffect } from "react"
import { Link } from "gatsby"
import HamburgerMenu from "./HamburgerMenu"
import { Button } from "components/button"
import { useSideNav } from "hooks/useSideNav"
import { ImageBuilder } from "_types/AssetsImage"
import "./header.module.scss"
import { getSideNavCloseIcon } from "assets/helper/icons"
import { getPhoneIcon } from "assets/helper/icons"
import { AnchorNumber } from "./AnchorNumber"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useTrackingNumberModal } from "hooks/useTrackingNumberModal"
import { TrackingNumberModal } from "components/modal/TrackingNumberModal/TrackingNumberModal"
import { fullWidthClassName } from "react-remove-scroll-bar"

interface HeaderProps {
  logo: ImageBuilder
  phone?: string
  sticky?: boolean
  children?: ReactNode
  hasToggleMenu?: boolean
  isLandingPage?: boolean
  customTrackingNumber?: string
}

export const LandingHeader = ({
  logo,
  hasToggleMenu = true,
  children,
  isLandingPage = false,
  customTrackingNumber,
}: HeaderProps) => {
  let smallerThanLaptop
  useEffect(() => {
    if (window) {
      smallerThanLaptop = window?.innerWidth < 1024
    }
  })

  const { companyName } = useGeneralSettings()
  const { isNavOpen, handleCloseNav } = useSideNav()
  const CLASS_CENTER_HEADER = `u-flex-center-align`
  const CLASS_PREVENT_HORIZONTAL_SHIFT_WITH_SIDENAV_OPEN = `u-unset-margin ${
    smallerThanLaptop ? fullWidthClassName : ""
  }`

  const { handleOpenModal, isModalOpen } = useTrackingNumberModal()

  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()
  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today!"

  const PhoneLink = ({ classNameModifier = "", showIcon = false }) => {
    const { trackingNumber, phoneNumber, id } = trackingNumbers[0]
    if (usePhoneNumberNotTrackingNumber && !phoneNumber) return <></>
    if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return <></>
    return (
      <AnchorNumber
        key={id}
        phoneNumber={phoneNumber}
        trackingNumber={trackingNumber}
        showIcon={showIcon}
        classNameModifier={classNameModifier}
        customTrackingNumber={customTrackingNumber}
        isLandingPage={isLandingPage}
      />
    )
  }

  const toggleMenuAndCloseButton = () => {
    if (!isNavOpen) {
      return <HamburgerMenu />
    } else {
      return (
        <Button
          variant="flashy-icon"
          onClick={handleCloseNav}
          extraClassNames="fr-header__close-button"
          aria-label="close side menu"
        >
          {getSideNavCloseIcon()}
        </Button>
      )
    }
  }

  return (
    <header
      className={
        !isLandingPage
          ? `fr-header ${CLASS_CENTER_HEADER}`
          : `fr-header fr-header__landing-page ${CLASS_CENTER_HEADER}`
      }
    >
      <div
        className={`fr-container fr-container--large fr-header__inner ${CLASS_PREVENT_HORIZONTAL_SHIFT_WITH_SIDENAV_OPEN}`}
      >
        <div className="fr-header__left">
          {trackingNumbers.length == 1 && (
            <PhoneLink classNameModifier="icon" showIcon />
          )}
          {trackingNumbers.length > 1 && (
            <>
              <Button
                variant="primary-page-link"
                extraClassNames="fr-header__phone-link__icon"
                onClick={handleOpenModal}
                aria-label="Call Office Button"
              >
                {getPhoneIcon()}
              </Button>
            </>
          )}
        </div>
        {trackingNumbers.length >= 4 && isModalOpen && (
          <div className={`fr-tracking-number-modal`}>
            <TrackingNumberModal />
          </div>
        )}
        {!isLandingPage && (
          <Link
            aria-label={`Go to ${companyName} Homepage`}
            to="/"
            className={`base-logo`}
          >
            <img
              src={`${logo.url}?q=60&w=200`}
              alt={logo.description || `${companyName} Logo`}
              loading="eager"
            />
          </Link>
        )}
        {isLandingPage && (
          <span className="base-logo">
            <img
              src={`${logo.url}?q=60&w=200`}
              alt={logo.description || `${companyName} Logo`}
              loading="eager"
            />
          </span>
        )}
        <div
          className={`fr-header__right ${
            isLandingPage ? "fr-header__right--landing-page" : ""
          }`}
        >
          {trackingNumbers.length == 1 && (
            <>
              <h5 className="fr-header__extra-call-text">
                {trackingNumberCTAText}
              </h5>
              <PhoneLink classNameModifier="link" />
            </>
          )}
          {trackingNumbers.length > 1 && (
            <>
              <Button
                variant="primary-page-link"
                extraClassNames="fr-header__phone-link__icon"
                onClick={handleOpenModal}
              >
                {getPhoneIcon()}
                {trackingNumberCTAText}
              </Button>
            </>
          )}
          <>{children}</>
          {hasToggleMenu && toggleMenuAndCloseButton()}
        </div>
      </div>
    </header>
  )
}
