import React from "react"
import { Banner } from "../HeroBanner-types"
import "./ppc-hero.module.scss"
import { FormBuilder } from "components/forms/FormBuilder"
import { GenericHero } from "../GenericHero/GenericHero"

interface HeroProps {
  banner: Banner
}

export const PpcHero = ({ banner }: HeroProps) => {
  const { background_image, heading, sub_heading, components } = banner

  const form = components?.modular_content.filter(
    component => component?.system?.type === "form"
  )[0]?.elements

  return (
    <section className="ppc-hero alternate-background-color">
      <GenericHero banner={{ background_image, heading, sub_heading }} />
      <div className="fr-container fr-container--large ppc-hero__form-wrapper">
        {form?.fields && (
          <FormBuilder
            ctaText={form?.submit_button_text?.value}
            submissionMessageHeading={form?.submission_message_heading?.value}
            submissionMessageBody={form?.submission_message_body?.value}
            fields={form.fields}
            formGridStyle="form-grid-2--full-width"
            customId={form?.custom_id?.value}
            isLandingPage={true}
          />
        )}
      </div>
    </section>
  )
}
