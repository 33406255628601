import React, { useEffect, ReactNode } from "react"
import { Helmet } from "react-helmet"
import { setTrackingCookies } from "assets/helper/setTrackingCookies"
import { useLogos } from "hooks/queries/settings/useLogos"
import { Footer } from "./footer/Footer"
import { LandingHeader } from "./main-header/LandingHeader"
import { useNavigationItems } from "hooks/useNavigationItems"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { LayoutContext } from "contexts/components/LayoutContext"
import { appendScript, appendInlineScript } from "../assets/helper/appendScript"
import "assets/scss/4-templates/landing-page-template.module.scss"
import { TrackingNumberModalProvider } from "contexts/TrackingNumberModalContext"

interface LandingPageLayoutProps {
  children: ReactNode
  location: string
  pathName: string
  linkedPagesUrlPathCodex: Record<string, string>
  mobilePreload?: string
  parentSlug?: string
  customTrackingNumber?: string
  isLandingPage?: boolean
}

export const LandingPageLayout = ({
  children,
  location,
  pathName,
  mobilePreload,
  linkedPagesUrlPathCodex,
  parentSlug,
  customTrackingNumber,
  isLandingPage,
}: LandingPageLayoutProps) => {
  const slug =
    location?.startsWith("/") && location !== "/"
      ? location.substring(1)
      : "home"

  const { customCSSUrl, scripts } = useGeneralSettings()

  const { footerNavigation, creditsNavigation } = useNavigationItems()

  const { footerLogo, headerLogo } = useLogos()

  let isMobile
  useEffect(() => {
    setTrackingCookies()
    if (window) {
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmit"
        ) {
          console.log("Form Submitted!", event)
          window.localStorage.setItem("leadSaved", "True")
        }
      })
      isMobile = window.innerWidth < 768
    }
    if (scripts) {
      scripts?.modular_content.map((script, index) => {
        script.elements.src_value?.value !== "" &&
          appendScript({
            id: `globalScript-${index + 1}`,
            scriptToAppend: script.elements.src_value?.value || "",
            isAsync: true,
          })

        script.elements.script?.value !== "" &&
          appendInlineScript({
            id: `globalScriptInline-${index + 1}`,
            scriptToAppend: script.elements.script?.value || "",
            isAsync: false,
          })
      })
    }
  }, [])

  return (
    <LayoutContext.Provider
      value={{
        linkedPagesUrlPathCodex,
        breadcrumbLocation: pathName,
        slug: location,
        parentSlug,

      }}
    >
      <div className={`wrapper wrapper--${slug === "/" ? "home" : slug}`}>
        <Helmet>
          {customCSSUrl && <link rel="stylesheet" href={customCSSUrl} />}
          {mobilePreload && isMobile && (
            <link rel="preload" as="image" href={mobilePreload} />
          )}
        </Helmet>

        <TrackingNumberModalProvider>
          <LandingHeader
            logo={headerLogo}
            hasToggleMenu={false}
            isLandingPage={true}
            customTrackingNumber={customTrackingNumber}
          />
        </TrackingNumberModalProvider>

        <main className={`page-content--landing-page`}>
          {children}
        </main>

        <Footer
          logo={footerLogo}
          creditsNavigation={creditsNavigation}
          footerNavigation={footerNavigation}
          isLandingPage={isLandingPage}
          customTrackingNumber={customTrackingNumber}
        />
      </div>
    </LayoutContext.Provider>
  )
}
